/*-------------------------------------------------------------

  #  Template Name: Sheolly - SEO & Digital Marketing Agency Bootstrap 5 Template
  #  Author   Name: 
  #  Version: 1.0

-------------------------------------------------------------
    
  #   CSS INDEX
========================================================
	
   [ 01. Bootstrap CSS ]

   [ 02. Template default CSS ]

   [ 03. header CSS ]

   [ 04. header top CSS ]

   [ 05. main menu CSS ]

   [ 06. sub menu CSS ]

   [ 07. hero section CSS ]

   [ 08. brand logo CSS ]

   [ 09. service card CSS ]

   [ 10. working process CSS ]

   [ 11. about us CSS ]

   [ 12. case studies CSS ]

   [ 13. team CSS ]

   [ 14. faq CSS ]

   [ 15. accordion CSS ]

   [ 16. testimonial CSS ]

   [ 17. blog CSS ]

   [ 18. footer CSS ]

   [ 19. bread crumb CSS ]

   [ 20. service page CSS ]

   [ 21. case details CSS ]

   [ 22. comment form CSS ]

   [ 23. blog details CSS ]

   [ 24. contact form CSS ]

   [ 25. page not found CSS ]


   [ The End ]


--------------------------------------------*/

/*--------------------------------------
/*  [ 01. Bootstrap CSS ]
----------------------------------------*/

@import "./bootstrap/functions";
@import "variabls";
@import "custom-variables";
@import "./bootstrap/bootstrap";

/*--------------------------------------
/*  Bootstrap Css End
----------------------------------------*/

/*--------------------------------------
/*   [ 02. Template default CSS ] 
----------------------------------------*/

@import "mixin";
@import "common";
@import "./component";



